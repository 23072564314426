import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsDashboardApi() {
    const { post, get } = ToolsApi();

    const getDashboardProducts = async (body) => {
        let url = "https://apipreprod.trustedsource.co.za/api/dashboard";
        let query = undefined;
        return post(url, query, body);
    };

    const getDashboardMessages = async (query) => {
        let url = "https://apipreprod.trustedsource.co.za/api/message-board";
        
        let body = undefined;
        return get(url, query, body);
    };

    const getDashboardItemsSummary = async () => {
        let url = Config.API_URL + "api/source-items/summary";
        
        let query = undefined;
        let body = undefined;
        return get(url, query, body);
    };

    const getDashboardMappingSummary = async (type, version) => {
        let url = Config.API_URL + "api/mapping/" + type + "/" + version + "/summary";
        
        let query = undefined;
        let body = undefined;
        return get(url, query, body);
    };

    return {
        getProducts: getDashboardProducts,
        getMessages: getDashboardMessages,
        getDashboardItemsSummary,
        getDashboardMappingSummary,
    };
}