import { useState } from "react";
import Cookies from 'universal-cookie';

export default function useWalkThrough() {
    const defaultValue = {
        "Dashboard" : false,
        "Workspace" : false,
        "AddProducts" : false,
        "Mapping" : false,
        "Reports" : false,
        "PublishManager" : false,
        "TargetModel" : false,
        "Conversions" : false,
    }

    const saveCookie = (value) => {
        const cookies = new Cookies();
        cookies.set('walkthrough', value, { path: '/' });
        setWtCookie(value);
    };

    const recoverCookie = () => {
        const cookies = new Cookies();
        return cookies.get('walkthrough')
    };

    const [wtCookie, setWtCookie] = useState(recoverCookie());

    const getCookie = (page) => {
        return wtCookie !== undefined ? wtCookie[page] : false;
    };

    const setCookie = (page, value) => {
        let newCookie = defaultValue;
        if(wtCookie !== undefined) {
            newCookie = wtCookie;
        }
        newCookie[page] = value;
        saveCookie(newCookie);
    };

    return {
        setCookie,
        getCookie,
    };
}
