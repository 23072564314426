import { createSlice } from "@reduxjs/toolkit";

export const picklistSlice = createSlice({
    name: 'picklist',
    initialState: {
        value: {picklist: undefined, rowPicklist: undefined, picklistConversions: undefined }
    },
    reducers: {
        setPicklist: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setPicklist } = picklistSlice.actions;

export default picklistSlice.reducer;