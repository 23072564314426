import { createSlice } from "@reduxjs/toolkit";

export const openOverlaySlice = createSlice({
    name: 'openOverlay',
    initialState: {
        value: false,
    },
    reducers: {
        setOpenOverlay: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setOpenOverlay } = openOverlaySlice.actions;

export default openOverlaySlice.reducer;