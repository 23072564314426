import { configureStore } from '@reduxjs/toolkit';
import productUploadReducer from '../features/productUploadSlice';
import onneMappingFilterReducer from '../features/onneMappingFilterSlice';
import dataModelReducer from '../features/dataModelSlice';
import sourceItemReducer from '../features/sourceItemSlice';
import selectedDataModelReducer from '../features/selectedDataModelSlice';
import gpcReducer from '../features/gpcSlice';
import optionsReducer from '../features/optionsSlice';
import targetModelReducer from '../features/targetModelSlice';
import refreshDataModelsReducer from '../features/refreshDataModelsSlice';
import dmFieldsDataReducer  from '../features/dmFieldsDataSlice';
import openAlertReducer from '../features/openAlertSlice';
import openDialogReducer from '../features/openDialogSlice';
import selectedIndexReducer from '../features/selectedIndexSlice';
import userAbilityReducer from '../features/userAbilitySlice';
import joyrideReducer from '../features/joyrideSlice';
import openUpdateNotificationReducer from '../features/openUpdateNotificationSlice';
import picklistReducer from '../features/picklistSlice';
import openOverlayReducer from '../features/openOverlaySlice';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

export default configureStore({
  middleware: customizedMiddleware,
  reducer: {
    productUpload: productUploadReducer,
    onneMappingFilter: onneMappingFilterReducer,
    dataModel: dataModelReducer,
    selectedDataModel: selectedDataModelReducer,
    gpc: gpcReducer,
    options: optionsReducer,
    targetModel: targetModelReducer,
    sourceItem: sourceItemReducer,
    refreshDataModels: refreshDataModelsReducer,
    dmFieldsData: dmFieldsDataReducer,
    openAlert: openAlertReducer,
    openDialog: openDialogReducer,
    selectedIndex: selectedIndexReducer,
    userAbility: userAbilityReducer,
    joyrideState: joyrideReducer,
    openUpdateNotification: openUpdateNotificationReducer,
    picklist: picklistReducer,
    openOverlay: openOverlayReducer,
  },
})