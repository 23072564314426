import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsDataModelApi() {
    const { post, get, deleteCall } = ToolsApi();

    const getDataModel = async (type, version, gpc) => {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/" + gpc;
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    };

    async function getDataModelEntry(type, version, fieldId) {
        let url = Config.API_URL + "api/datamodelentry/" + type + "/" + version + "/" + + fieldId;
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getDataModels(type, version, fieldId) {
        let url = Config.API_URL + "api/datamodels";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getDataModelPicklist(type, version, gpc) {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/" + gpc + "/picklists";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getPicklistRulesMapped(type, version, gpc) {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/" + gpc + "/conversion-rules-mapped";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getPicklistRules(type, version, gpc, picklistId) {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/" + gpc + "/picklist/" + picklistId + "/conversion-rules";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function postPicklistRules(type, version, gpc, picklistId, data) {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/" + gpc + "/picklist/" + picklistId + "/conversion-rules";
        
        let body = data
        let query = undefined
        return post(url, query, body);
    }

    async function migrateDatamodel(type, from, to) {
        let url = Config.API_URL + "api/datamodel/" + type + "/upgrade";
        let body = {"from": from, "to": to};
        let query = undefined
        
        return post(url, query, body);
    }

    return {
        getDataModel,
        getDataModelEntry,
        getDataModels,
        getDataModelPicklist,
        getPicklistRules,
        postPicklistRules,
        getPicklistRulesMapped,
        migrateDatamodel
    };
}