import { useState, useEffect } from "react";

export default function useCollapsable() {
    const [collapseGroups, setCollapseGroups] = useState({});
    const [collapsedItems, setCollapsedItems] = useState([]);

    const removeElementsCollapsed = (elements, fieldId) => {
        let oldItems = collapsedItems
        let newItems = oldItems.filter(function(item) {
            return !elements.includes(item);
        })
        setCollapsedItems(newItems);

        let collapse = {...collapseGroups};
        let newValue = !collapseGroups[fieldId];
        
        collapse[fieldId] = newValue;
        setCollapseGroups(collapse);
    }

    const addElementsCollapsed = (elements, fieldId) => {
        let oldItems = collapsedItems
        const concatArr = oldItems.concat(elements)
        const newItems = concatArr.filter((item, idx) => concatArr.indexOf(item) === idx)
        setCollapsedItems(newItems);

        let newValue = !collapseGroups[fieldId];
        let collapse = {...collapseGroups};
        
        collapse[fieldId] = newValue;
        elements.forEach(element => {
            if(Object.keys(collapseGroups).includes(element)) {
                collapse[element] = newValue;
            }
        });
        setCollapseGroups(collapse);
    }


    const handleCollapseChange = (row, items) => {
        let newValue = !collapseGroups[row.fieldId];
        let group = getItems(row, items, newValue);
        group = group.map(elemRes => elemRes.fieldId);
        if(group.length > 0) {
            if(newValue) {
                removeElementsCollapsed(group, row.fieldId);
            } else {
                addElementsCollapsed(group, row.fieldId);
            }  
        }
        
        
    }

    const getItems = (row, items, value) => {
        let group = [];
        
        if(row.group !== undefined && row.group !== null && row.group === row.fieldId && row.child !== true) {
            group = items.filter((elem) => { return elem.parentId === row.fieldId});
        } else {
            if(row.multivalue) {
                group = items.filter((elem) => { return elem.parentId === row.fieldId});
            } else {
                if(row.multilingual) {
                    group = items.filter((elem) => { return elem.parentId === row.fieldId});
                }
            }
        }

        if(!value) {
            let test = group.filter((elem) => {return Object.keys(collapseGroups).includes(elem.fieldId)})
            test.forEach(element => {
                group = group.concat(getItems(element, items, value));
            });
        }
        return group;
    }

    return {
        collapseGroups,
        setCollapseGroups,
        collapsedItems,
        setCollapsedItems,
        handleCollapseChange,
    };
}