import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Typography, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import ToolsUpdateNotification from "../../tools/ToolsUpdateNotification";
import CloseIcon from '@mui/icons-material/Close';

let titleStyle = {
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#3684C2',
    width: 'auto'
}

let textStyle = {
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#666E71',
}

let buttonStyle = {
    background: '#FFFFFF',
    border: '1px solid #1EB1E0',
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#3284C6',
    padding: '10px',
    textTransform: 'none',
    borderRadius: '0px',
    minWidth: '180px',
    '&:hover': {
        background: '#E5F6FD',
    }
}

export default function UpdateNotification() {
    const upd = useSelector((state) => state.openUpdateNotification.value);
    const { close } = ToolsUpdateNotification();

    const handleUpdateNotificationClose = () => {
        close();
    }

    return  <Grid container direction="column" sx={{padding: '15px', paddingTop: '5px'}} rowSpacing={1}>
                <Grid item container direction="row" alignContent="flex-start">
                    <Grid item xs={11} sx={{paddingTop: "20px"}}>
                        <Typography variant="h1" component="h1" style={titleStyle}>
                            Update Notification
                        </Typography>
                    </Grid>
                    <Grid item xs={1} justifyItems="flex-end" alignContent="flex-start" >
                        <IconButton color="primary" component="label" onClick={handleUpdateNotificationClose}>
                            <CloseIcon style={{color: "#000000"}} />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="p" component="p" style={textStyle}>
                        {upd.description}
                    </Typography>
                </Grid>
                <Grid item container justifyContent="space-around">
                    <Grid container item justifyContent={'flex-start'} xs={6}>
                    </Grid>
                    <Grid container item justifyContent={'flex-end'} xs={6}>
                        <Button style={buttonStyle}>See details</Button>
                    </Grid>
                </Grid>
            </Grid>
             
}